<template>
<b-row>
 <b-col cols="12"><h1 class="section-title">Get A Hold of Me.</h1></b-col>
<b-col cols="12">
   <b-form ref="form" width="100%" @submit.stop.prevent="sendForm">
                            <b-form-group id="input-group-2" label="Your Name:" label-for="name-input">
                            <b-form-input
                              id="name-input"
                              class="name-input"
                              placeholder="Enter Name"
                              v-model="$v.name.$model"
                              :state="validateName('name')"
                              required
                            ></b-form-input>
                                 <b-form-invalid-feedback
          id="name-input-live-feedback"
        >This is a required field and must be at least 6 characters.</b-form-invalid-feedback>
                          </b-form-group>


                          <b-form-group
                            id="email-input-group"
                            label="Email Address:"
                            label-for="email-input"
                            description=""
                          >
                            <b-form-input
                              id="email-input"
                              class="email-input"
                              v-model="$v.email.$model"
                              :state="validateEmail('email')"
                              type="email"
                              placeholder="Enter email"
                              required
                            ></b-form-input>
                             <b-form-invalid-feedback
          id="email-input-live-feedback"
        >Please enter the correct Email address.</b-form-invalid-feedback>
                          </b-form-group>

                          <b-form-group id="input-group-3" label="What Are You Inquiring About:" label-for="input-3">
                            
                             <b-form-select v-model="$v.select.$model" :state="validateSelect('select')" :options="items" @change="checkselect"></b-form-select>

            

                          </b-form-group>

                          <b-form-group id="textarea-group" label-for="textarea">
                            <b-form-textarea
                                id="textarea"
                                class="textarea"
                                placeholder="Please provide in more detail..."
                                v-model="$v.textarea.$model"
                                :state="validateTextBox('textarea')"
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                                <b-form-invalid-feedback
                                id="textarea-live-feedback">
                                Needs to be at least 20 to 255 characters..
                              </b-form-invalid-feedback>
                          </b-form-group>

                          <b-form-group id="input-group-4" label-for="form-button">  
                          <b-row style="margin-top:1em;">
                                <b-col md="10" xs="12">         
                                </b-col>
                                <b-col  md="2" xs="12">
                                        <b-button class="btn-lg ml-5" id="form-button" @click="validate" type="submit" variant="primary">Submit</b-button>
                                </b-col>
                          </b-row>
                          </b-form-group>
</b-form>
                       
</b-col>
</b-row>



                     
</template>

<style scoped>
.name-input  {
  font-family:"Railway";
  font-weight:600;
}
.email-input {
  font-family:"Railway";
  font-weight:600;
}
.textarea {
  font-family:"Railway";
}
.textarea:focus {
font-size:16px;
font-weight:600;
}
</style>

<script>
import axios from "axios"
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email } from "vuelidate/lib/validators"

export default {
  name: 'Contact-template',
  mixins: [validationMixin],  
  data: () => ({
    email:'',
    name: '',
    textarea: '',  
    select: 'Pick from the list below',
    selected: false,
    items: [
      'Pick from the list below', 
      'Request Custom Website',
      'Freelance Opportunity',
      'Wordpress Website',
      'Hosting and or Containerization',
      'HubSpot CMS',
      'Other',
    ],
    turnoff:false,
    alreadySent:false,
    newSent:false,
  }),
  validations: {
      name: {
        required,
        minLength: minLength(6)
      },
      email: {
        required,
        email
      },
      select: {
        required,
        selected: true
      },
      textarea: {
        required,
        minlength:minLength(20),
        maxlength:maxLength(500)
      }
  },
  watch:{
  },
  methods: {
  validateName(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
  },
   validateEmail(email) {
      const { $dirty, $error } = this.$v[email];
      return $dirty ? !$error : null;
  }, 
   checkselect: function () {
        let checkselect = this.items[0] !== this.select ? true: false;
        this.selected = checkselect;
        return this.selected;
  },
   validateSelect(selected) {
    //  console.log(selected);
      const { $dirty, $error } = this.$v[selected];
      return $dirty ? !$error : null;
  }, 
   validateTextBox(textarea) {
      const { $dirty, $error } = this.$v[textarea];
      return $dirty ? !$error : null;
  },     
  sendForm() {
      //let sendForm = async function sendForm(){
          const name = this.name;
          const email = this.email;
          const item = this.select;
          const description = this.textarea;
        //    const name = "Brandon";
        //   const email = "trujillobrandon2@gmail.com";
        //   const item = "wordpress";
        //   const description = "test new 2022";
          const url = "https://lakewebdesigns.com/sendtheform";

          let payload = {name:name,email:email,item:item,detail:description};
          console.log(payload);
           //let res = await axios.post(url,payload);
           axios.post(url,payload);
           //return res;
      //    }    
      //console.log(sendForm);     
  },      
    validate () {

      if (this.$v.form.$anyError) {
        return;
      }
    //   this.$refs.form.validate()
    //   console.log(this.$refs.form.validate())
    //   if(this.$refs.form.validate() == true && this.alreadySent == false && document.cookie != "form is sent"){
    //   console.log(document.cookie);
    //       const name = this.name;
    //       const email = this.email;
    //       const item = this.select;
    //       const description = this.textarea;
    //       const url = "/sendtheform";

    //       let payload = {name:name,email:email,item:item,detail:description};

    //     //   async function sendForm(){
    //     //   let res = await axios.post(url,payload);
    //     //   return res;
    //     //   }
    //     //thissendForm();
    //     console.log(axios,url,payload)
    //   this.sendForm().then(res => {


    //   let data = res.data;
    //   if(data != ""){
    //     this.newSent = true;
    //     this.turnoff = true;
    //     document.cookie = "formSent=true";
    //     document.getElementById("form-button").disabled = true;
    //   }


    //   });
    //   }else{
    //    this.alreadySent = true;
    //    this.turnoff = true;
    //    document.getElementById("form-button").disabled = true;
    //   }

            this.sendForm();
        }  
    }
}

</script>