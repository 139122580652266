<template>
<footer class="bg-info text-center text-lg-start footer">
            <!-- Copyright -->
            <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
          &copy; 2012 - {{date}} {{time}} — <strong class="footerbrand">LakeWebDesigns BT</strong>
            </div>
            <!-- Copyright -->
</footer>
      
</template>
<style lang="scss">
.footer {
  
}

.footerbrand {
  font-family:'Railway';
}
</style>

<script>
export default {
  name: 'Footer-template',
  data() {
    return {
      interval: null,
      time: null,
      todaysdate:null,
      date:null
    }
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval)
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)

    this.todaysdate = new Date();
    this.date = this.todaysdate.getFullYear();

  }
}
</script>