<template>
       <b-row>
        <b-col cols="12"> <h1 class="section-title">About Myself.</h1></b-col>
                    <b-col sm="12" md="6">
                        <div>
                             <b-img class="about-pic" thumbnail fluid :src="about[0].image.url" width="512" height="640" alt="Image 1"></b-img>
                        </div>
                    </b-col>
                    <b-col sm="12" md="6">
                    <div class="console">
                        <header>
                          <p>Brandon@LakeWebDesigns.com</p>
                        </header>
                        <div class="consolebody">
                          <p>> Type help to see a list of commands</p>
                          <div v-html="about[0].bio.html">
                          </div>
                        </div>
                    </div>
                        
                    </b-col>  
       <b-col cols="12">
       
       </b-col>                                                
       </b-row>
</template>
<style lang="scss">

.about-pic {
  width:100%;
  height:auto;
}

.console {
  font-family: 'Fira Mono';
  width:100%;
  height: 20em;
  box-sizing: border-box;
  margin: auto;
  margin-top:1em;
  text-align:left;
}

.console header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #555;
  height: 2.8125em;
  line-height: 45px;
  text-align: center;
  color: #DDD;
}

.console .consolebody {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-sizing: border-box;
  padding: 20px;
  height: calc(100% - 40px);
  overflow: scroll;
  background-color: #000;
  color: #63de00;
}

.console .consolebody p {
  line-height: 1.5rem;
}

.command-line-white {
  color:$white;
}

.command-line-blue {
  color:$blue;
}

</style>

<script>
const axios = require("axios").default;
export default {
  name: 'The-About-template',
data() {
  return {
    about: []
  };
},
created() {
  // fetch the data when the view is created and the data is
  // already being observed
  this.fetchData();

},
methods: {
  fetchData() {

              const projecturl = 'https://api-us-west-2.graphcms.com/v2/cknw7xq23x56x01wjch28gjaw/master';


              const getabout = {
              method: 'post',
              url: projecturl,
              headers: {
                'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MTk0MTA0MTAsImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuZ3JhcGhjbXMuY29tL3YyL2Nrbnc3eHEyM3g1NngwMXdqY2gyOGdqYXcvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiNmM4ZGNjYWEtNjgxNi00YjAwLTk1OGItNDgzNTZmYTVlOTBhIiwianRpIjoiY2tueTM1OW5nMjJtNDAxeXphdnpsaHdpbCJ9.34zOXyY72zPQDmWWP3ieFBck9F9nd9A-rdUaMYwhEvOWd0k0RJrEd-qw10DzKfKsJIxCuQvbx-TM5_ecJUj4Fii3TJUCLGKuAwwPmt6B8AcHABt8dYATjTBTICX2kIShlEyjmZVpIOphLJPhgps1EjbIeEmDEI5MjYFhc8tZvYBvhrCq7k2PIC1JYjnJdUX-6hR7tWf_GboAeZ0uCLTg22GnoXYo3meDCQfeu2KWxt05WiYYoUTz52WvG8Ur8koQa0AydC55SFtmVF4cy3kLZTbCGr7BedCFRNeBinS1Rd-qLrcobvzNYOxVCHa4u9NQNR5tJJBaDnb08RdYSs0PLGK5eEa5u2A0N85Zygypy-HxXFsWjN4pChcVv0icyWVxVlCUyc9bIL4kYJ0EzaRz4dRIQoMJtWH_xHdjemRXZOFU7sU8ak0EdZUwnP73WsJb8scDpX78qOPfIHu53LOjtOeHPhmnDIylMTw0NYGYtNaFZulqTn6FQhujg6FCevThtYbJIPrUUyMtCnQ0RPKvojZ7Mf6YYVfufhpbw3ON1mNM_K2hFhJPbNCoPf6HywWHyMFquyOLvb2FzQ0V4fl_EwSLWUGmLJxSFgg-JKBpuB5A6jgNfL5gdx6jvplxhPYu1mL7kK4nWwyaneFWPTx0ngQ2BnnNN96f7BUbDQdGePU'
              },
              data: {
                    query: `{
                    abouts {
                                title
                                subtitle
                                      image {
                                            url
                                      }
                                      bio {
                                            html
                                      }
                                }
                            }`
                      }
              };

              let about = this.about;

              let image = this.image;
              console.log(image);


              axios(getabout)
              .then(function (response) {
                   let aboutData = response.data.data.abouts[0];
                   //console.log(aboutData);
                  about.push(aboutData);
                  //console.log(aboutData.image.url);




          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })

  }

}

}
</script>