<template>
  <b-container fluid class="maincontainer" style="overflow: hidden;height:auto;">
              <b-container id='#top' class="container home" style="min-height: 100vh;">
               
                <div class="customheader" header="Lake Web Designs">
                   <h1 class="title">LakeWebDesigns</h1><br/>
                   <span class="psalm">{{msg}}</span>
                </div>
                
              </b-container>  
              <b-container id='#introduction' class="container introduction" style="min-height: 100vh;margin-top:1em;">
                
                <Introduction/>
                                                           
                
              </b-container>      
              
          <b-container id='#about' class="container about" style="min-height: 100vh;margin-top:1em;">
               
                    <About/>
              
          </b-container>  

           <b-container id='#contact' fluid class="container contact" style="min-height: 100vh;margin-top:1em;">
                
                     <Contact/>
                
          </b-container> 
           <b-container id='#projects' fluid class="container projects" style="min-height: 100vh;margin-top:1em;">
                 
                     <Projects/>
                
           </b-container>
           <Footer/>
    </b-container>
</template>
<style lang="scss">
.maincontainer {
  height:90vh;
  width:100vw;
}
.customheader {
    padding-top:10em;
}

.title {
  font-size:4rem;
}

.psalm{
      font-family: 'Railway';
      font-size: 1.25rem;
}

.motivational-message{
    color:#3577c1;
    font-weight:600;
    font-size:1.25em;
}



/* Mosiac Styling */

.item {
	display: inline-block;
	margin:15px 0;
}

.well {
	background-color : #bada55;
	border:none;
	box-shadow: 0 20px 80px #888;
}

.well h2 {
	font-weight:700;
}

.home {
    background: rgba(0, 0, 0, 0.1);
} 

.about {
    background: rgba(0, 0, 0, 0.1);
}

.contact {
    background: rgba(0, 0, 0, 0.1);
}

.introduction {
  background: rgba(0, 0, 0, 0.4);
  padding:1em;
}

.projects {
    background: rgba(0, 0, 0, 0.1);
    max-height: 100vh;
     overflow-y: scroll;
     scrollbar-width: none;
}


</style>

<script>
import About from './About.vue';
import Contact from './Contact.vue';
import Projects from './Projects.vue';
import Footer from './Footer.vue';
import Introduction from './Intro.vue';
export default {
  name: 'Home-page',
  props: {
    msg: String
  },
  components: {
    About,
    Introduction,
    Contact,
    Projects,
    Footer
  }
}
</script>