<template>
  <div id="header">
    <b-navbar class="py-0 custom-nav" toggleable="lg" type="dark" variant="info">
      <b-navbar-brand @click="goToHash('#top')" href="#"><b-img fluid src="../assets/Lake_Web_Designs.png" class="logo" alt="Responsive image"
          width="54" hieght="54"></b-img></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="https://blog.lakewebdesigns.com" target="_blank">Blog <svg xmlns="http://www.w3.org/2000/svg"
              width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg></b-nav-item>
          <b-nav-item @click="goToHash('#introduction')" href="#introduction">About <svg xmlns="http://www.w3.org/2000/svg" width="16"
              height="16" fill="currentColor" class="bi bi-file-person-fill" viewBox="0 0 16 16">
              <path
                d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z" />
            </svg></b-nav-item>
          <b-nav-item @click="goToHash('#contact')" href="#contact">Contact <svg xmlns="http://www.w3.org/2000/svg" width="16"
              height="16" fill="currentColor" class="bi bi-postcard" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5ZM10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3ZM13 8h-2V6h2v2Z" />
            </svg></b-nav-item>
          <b-nav-item @click.prevent="goToHash('#projects')" href="#projects">Projects <svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-terminal-fill"
              viewBox="0 0 16 16">
              <path
                d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm9.5 5.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm-6.354-.354a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146z" />
            </svg></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  methods: {
    goToHash: function (e) {
      //console.log(e);
      //console.log(window.location.href);
      // if (typeof getHash == "undefined"){
      //   var getHash;
      // }else{
      //   getHash = "";
      // }
var getHash = e;
      //var getHash = window.location.hash;
      //console.log(getHash);
      //let hash;
      //     var index = getHash.indexOf("#");
      //     index = '#'+ index;
      //     console.log(index); 
      // if (index !== -1)
      // {
      //     hash = index.substring(index + 1);
      //     console.log(hash);
      // }
      //console.log(this.$emit);
      //this.$emit('goToHash', getHash);
      //console.log(document.getElementById(getHash));
      document.getElementById(getHash).scrollIntoView();
    },
    addStickyNav: function () {
      // When the user scrolls the page, execute myFunction
      window.onscroll = function () { stickynav() };

      // Get the header
      var header = document.getElementById("header");

      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
      function stickynav() {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      }
    }
  },
  props: {
    msg: String
  },
  mounted() {
    this.addStickyNav();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.custom-nav {
  height: 3.75rem;
  /*60px*/
}

.logo {
  height: auto;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.nav-item a {
  font-family: 'Railway';
  color: #ffffff !important;
  background: #284243;
}

.nav-item a:hover {
  background: #ffffff;
  color: #000 !important;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}</style>
