<template>
  <div id="app">
  
      <main>
          <NavBar /> 
              <Home v-bind:msg="quote" />         
      </main>
     
 </div>
</template>
   
<script>
const axios = require("axios").default;
import * as THREE from 'three';
import * as POSTPROCESSING from 'postprocessing';
import NavBar from './components/NavBar.vue';
import Home from './components/Home';
import Footer from './components/Footer.vue';
import Smoke from './assets/clouds-smoke-png-11.png';
//let texture = new THREE.Texture();
let loader = new THREE.TextureLoader();
//console.log("This is the loader", loader);
//var script = document.currentScript;
//var fullUrl = script.src;
//console.log(loader.load("/img/Lake_Web_Designs.5916f524.png"));

//console.log(fullUrl);
export default {
  name: 'App',
   data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      cloudParticles: [],
      composer:null,
      quote:null,
      interval:null,
      selectedPage: null,
    }
  },
  methods: {
  init: function() {
  let bg = document.getElementById('app');
  //let bg = document.getElementById('app')
  this.scene = new THREE.Scene();
  this.camera = new THREE.PerspectiveCamera(60,window.innerWidth/window.innerHeight,1,1000);
  this.camera.position.z = 1;
  this.camera.rotation.x = 1.16;
  this.camera.rotation.y = -0.12;
  this.camera.rotation.z = 0.27;

  let ambient = new THREE.AmbientLight(0x555555);
  this.scene.add(ambient);

  let directionalLight = new THREE.DirectionalLight(0xff8c19);
  directionalLight.position.set(0,0,1);
  this.scene.add(directionalLight);
   
  let orangeLight =  new THREE.PointLight(0xcc6600,50,450,1.7);
  orangeLight.position.set(200,300,100);
  this.scene.add(orangeLight);
  let redLight =  new THREE.PointLight(0xd8547e,50,450,1.7);
  redLight.position.set(100,300,100);
  this.scene.add(redLight);
  let blueLight =  new THREE.PointLight(0x1d4769,50,450,1.7);
  blueLight.position.set(200,300,100);
  this.scene.add(blueLight);

  this.renderer = new THREE.WebGLRenderer();
  this.renderer.setSize(window.innerWidth,window.innerHeight);
  this.scene.fog = new THREE.FogExp2(0x191c03, 0.001);
  this.renderer.setClearColor(this.scene.fog.color);
  //app.appendChild(this.renderer.domElement);
  //bg.setAttribute("data-engine","three.js r138");
  //bg.setAttribute("width","697");
  //bg.setAttribute("height","629");
  //bg.setAttribute("width","697");
  //bg.style.display = "block";
  //bg.style.width = "697px";
  //bg.style.height = "629.px";

  //console.log(this.renderer);
  bg.appendChild(this.renderer.domElement);
  //bg.append(this.renderer.domElement);
  },
  render: function(){
  this.cloudParticles.forEach(p=>{
  p.rotation.z -=0.001;
  });
  this.composer.render(0.1);
  //this.renderer.render(this.scene,this.camera);
  requestAnimationFrame(this.render);
  },
  clouds: function() {
  var geometry = new THREE.PlaneGeometry(500, 500);
  //loader.setCrossOrigin = "https://www.freepnglogos.com";
  //let smoke = "https://www.freepnglogos.com/uploads/smoke-png/clouds-smoke-png-11.png";
  let smoke = Smoke;
  //console.log("Fix me");
  var material = new THREE.MeshLambertMaterial({
    map: loader.load(smoke),
    transparent:true
    });

// set the position of the image mesh in the x,y,z dimensions
for(let p=0;p<25;p++){
var mesh = new THREE.Mesh(geometry, material);
//var cloudParticles = [];
mesh.position.set(
Math.random()*800 -400, 
500,
Math.random()*500-450
);
mesh.rotation.x = 1.16;
mesh.rotation.y = -0.12;
mesh.rotation.z = Math.random()*360;
mesh.material.opacity = 0.6;
this.cloudParticles.push(mesh);
this.scene.add(mesh);
}
// add the image to the scene
},
onWindowResize:function(){
this.camera.aspect = window.innerWidth / window.innerHeight;
this.camera.updateProjectionMatrix();
this.renderer.setSize(window.innerWidth, window.innerHeight);
},
postprocessing: function(){
// console.log(THREE);
// console.log(POSTPROCESSING);
// console.log("Postprocessing");
let textureEffect;
function addPostProcessing(imagespace){
    let texture = imagespace;
    //console.log(imagespace);
    textureEffect = new POSTPROCESSING.TextureEffect({
                    blendFunction: POSTPROCESSING.BlendFunction.COLOR_DODGE,
                    texture: texture
                  });
    textureEffect.blendMode.opacity.value = 0.2;
    //console.log(textureEffect);
    return textureEffect;
}
 
let imagespace = loader.load( 'https://images.unsplash.com/photo-1616712134411-6b6ae89bc3ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80');
//let imagespace = loader.load( 'https://unsplash.com/photos/uXchDIKs4qI');
addPostProcessing(imagespace);
//console.log(imagespace);
const bloomEffect = new POSTPROCESSING.BloomEffect({
blendFunction: POSTPROCESSING.BlendFunction.COLOR_DODGE,
kernelSize:POSTPROCESSING.KernelSize.SMALL,
useLuminanceFilter:true,
lumanceThreshold:0.3,
luminanceSmoothing:0.75
});
bloomEffect.blendMode.opacity.value = 1.5;
let effectPass = new POSTPROCESSING.EffectPass(
this.camera,
bloomEffect,
textureEffect
);
effectPass.renderToScreen = true;
this.composer = new POSTPROCESSING.EffectComposer(this.renderer);
this.composer.addPass(new POSTPROCESSING.RenderPass(this.scene,this.camera));
this.composer.addPass(effectPass);

window.addEventListener("resize", this.onWindowResize, false);

},
getQuote: function(){ 

    //console.log(axios);
    // let options = {
    //           method: 'POST',
    //           url: 'https://motivational-quotes1.p.rapidapi.com/motivation',
    //           headers: {
    //             'content-type': 'application/json',
    //             'x-rapidapi-key': '0988aee693msh129185b827a6e5bp11ec90jsn9ceee658c919',
    //             'x-rapidapi-host': 'motivational-quotes1.p.rapidapi.com'
    //           },
    //           data: {key1: 'value', key2: 'value'}
    //         };

    //         import axios from 'axios';

      let options = {
        method: 'GET',
        url: 'https://quotes-inspirational-quotes-motivational-quotes.p.rapidapi.com/quote',
        params: {
          token: 'ipworld.info'
        },
        headers: {
          'X-RapidAPI-Key': 'f5347d3171mshedb711929b9e18ep133085jsn9db7a18ee9c2',
          'X-RapidAPI-Host': 'quotes-inspirational-quotes-motivational-quotes.p.rapidapi.com'
        }
      };
    //    async () => {
    //   try {
    //     const response = await axios.request(options);
    //     console.log(response.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    //  };

    //     console.log(options);  
       // async () => {
            let getq = axios.request(options).then(function (response) {
                      let qouteData = response.data;
                      var qouteDiv = document.createElement("div");
                      var text = document.createElement("i");
                      var author = document.createElement("p");
                      var qoute = document.createTextNode(qouteData.text);
                      //var authorText = document.createTextNode(qouteData.author);
                      var authorData = ' -' + qouteData.author;
                      var authorText = document.createTextNode(authorData);
                      //qouteDiv.appendChild(text);
                      text.appendChild(qoute);
                      author.appendChild(authorText);
                      qouteDiv.appendChild(text);
                      qouteDiv.appendChild(author);
                      //console.log(qouteDiv);
                      //qouteDiv.appendChild(author);
                      //qouteDiv.appendChild(authorText);
                      //text.append(qoute);
                      //authorText.append(author);

                      //let qs = JSON.stringify(qouteData);
                      return qouteDiv.innerText;
                }).catch(function (error) {/* eslint-disable */
                  console.error(error);
            });
            getq.then(value => {
                this.quote = value;
            })
        //}                   
  },//End of getquery 
  newQuote: function(){ 
    this.interval = setInterval(() => { 
    console.log("Getting New Qoute");
    // let options = {
    //           method: 'POST',
    //           url: 'https://motivational-quotes1.p.rapidapi.com/motivation',
    //           headers: {
    //             'content-type': 'application/json',
    //             'x-rapidapi-key': '0988aee693msh129185b827a6e5bp11ec90jsn9ceee658c919',
    //             'x-rapidapi-host': 'motivational-quotes1.p.rapidapi.com'
    //           },
    //           data: {key1: 'value', key2: 'value'}
    //         };
    let options = {
        method: 'GET',
        url: 'https://quotes-inspirational-quotes-motivational-quotes.p.rapidapi.com/quote',
        params: {
          token: 'ipworld.info'
        },
        headers: {
          'X-RapidAPI-Key': 'f5347d3171mshedb711929b9e18ep133085jsn9db7a18ee9c2',
          'X-RapidAPI-Host': 'quotes-inspirational-quotes-motivational-quotes.p.rapidapi.com'
        }
      };
       async () => {
      try {
        const response = await axios.request(options);
        //console.log(response.data);
      } catch (error) {
        console.error(error);
      }
     };
        //console.log(options);  
        let getq = axios.request(options).then(function (response) {
                  let qouteData = response.data;
                  //let qs = JSON.stringify(qouteData);
                  return qouteData;
            }).catch(function (error) {/* eslint-disable */
            	console.error(error);
        });
        getq.then(value => {
            this.quote = value;
        })  
     },30000)   
  //clearInterval(this.interval);                     
  }//End of getquery   
  },
  components: {
    NavBar,
    Home,
    Footer
  },
  mounted() {
      this.init();
      this.postprocessing();
      this.render();
      this.clouds();
      this.getQuote();
      //this.newQuote();  
      // const interval = setInterval(function() {
      //   this.getQuote();
      // }, 1000);

      // clearInterval(interval); // thanks @Luca D'Amico

      //let renderHome = HelloWorld.render;
      //let canvas = document.querySelectorAll("canvas");
      //canvas.innerHTML ="pfdgkljgfdskjldfgskljsdfkljsdfkjl";
      //console.log(canvas);
/*
      window.addEventListener('load', (event) => {
       //console.log('page is fully loaded');
       console.log('Always Available on the Web, please fill out Contact Form,Call Me, or email me for a free quote.');
      });
*/

      // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
}
}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width:640px;
}


canvas {
  position: fixed;
  top: 0;
  left: 0;
}

main {
  width: 100vw;
  color: white;
  z-index: 99;
  position: absolute;
  width: 100%;
  margin: 0px auto;
}
</style>
