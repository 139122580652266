<template>
                <b-row>
                    <b-col cols="12"><h1 class="section-title">Here Are Some Projects.</h1></b-col>
                                <b-col sm="12" md="6" lg="4" class="card-spacing" v-for="(project, index) in projects[0]" :key="index">
                                    <a :href="project.url" target="_blank">
                                                                <b-card
                                                                  overlay
                                                                  no-body
                                                                  :img-src="project.img.url"
                                                                  :img-alt="project.img.alt"
                                                                  text-variant="white"
                                                                  sub-title=""
                                                                  class="project-card sm-12"
                                                                  @mouseover="showWordIndex = index" 
                                                                  @mouseleave="showWordIndex = null"
                                                                  v-on:click="showdivinfo($event)"
                                                                >
                                                              
                                                              <div class="project-text" v-if="showWordIndex === index">
                                                                        <b-card-title class="project-title">{{project.title}}</b-card-title>
                                                                    <b-card-text>                                                                 
                                                                        <span v-html="project.about.html"></span>
                                                                    </b-card-text> 
                                                              </div>
                                                                    
                                                                </b-card>
                                    </a>                                
                    </b-col>                    
                </b-row>      
</template>

<style scoped>
.project-card {
  /*width:333px;*/
  height:190px;
  overflow:hidden;
  margin-bottom:10px;
  object-fit: none;  
  object-position: center top; 
  background-size: cover;
  border-radius: 20px; 
  border:solid 1px $grey;
  margin-top:.6em;
}

.project-title {
  color:#000;
  padding-top:2px;
}

.project-text {
  background:#00939e;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  border-top:solid 1px rgba(0, 0, 0, 0.1);
}

.card-spacing {
  margin-bottom:1.6em;
}
</style>

<script>
import axios from "axios"
export default {
  name: 'Projects-template',
  data() {
    return {
      projects: [],
      lazy:'/img/Lake_Web_Designs.png',
      hover:false,
      projectinfo:false,
      showWordIndex: null,
      mainProps: { blank: true, blankColor: '#777', width: 250, height: 250, class: 'm1' }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
  //  this.projects;
    this.fetchData();

  },
  computed:{
  about() {
    return this.hover ? 'about' : 'hide';
  },
  },
  methods: {
    showdivinfo:function($event){
     console.log($event);
    },
    fetchData() {

      const projecturl = 'https://api-us-west-2.graphcms.com/v2/cknw7xq23x56x01wjch28gjaw/master';

  const getprojects = {
  method: 'post',
  url: projecturl,
  headers: {
      'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MTk0MTA0MTAsImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuZ3JhcGhjbXMuY29tL3YyL2Nrbnc3eHEyM3g1NngwMXdqY2gyOGdqYXcvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiNmM4ZGNjYWEtNjgxNi00YjAwLTk1OGItNDgzNTZmYTVlOTBhIiwianRpIjoiY2tueTM1OW5nMjJtNDAxeXphdnpsaHdpbCJ9.34zOXyY72zPQDmWWP3ieFBck9F9nd9A-rdUaMYwhEvOWd0k0RJrEd-qw10DzKfKsJIxCuQvbx-TM5_ecJUj4Fii3TJUCLGKuAwwPmt6B8AcHABt8dYATjTBTICX2kIShlEyjmZVpIOphLJPhgps1EjbIeEmDEI5MjYFhc8tZvYBvhrCq7k2PIC1JYjnJdUX-6hR7tWf_GboAeZ0uCLTg22GnoXYo3meDCQfeu2KWxt05WiYYoUTz52WvG8Ur8koQa0AydC55SFtmVF4cy3kLZTbCGr7BedCFRNeBinS1Rd-qLrcobvzNYOxVCHa4u9NQNR5tJJBaDnb08RdYSs0PLGK5eEa5u2A0N85Zygypy-HxXFsWjN4pChcVv0icyWVxVlCUyc9bIL4kYJ0EzaRz4dRIQoMJtWH_xHdjemRXZOFU7sU8ak0EdZUwnP73WsJb8scDpX78qOPfIHu53LOjtOeHPhmnDIylMTw0NYGYtNaFZulqTn6FQhujg6FCevThtYbJIPrUUyMtCnQ0RPKvojZ7Mf6YYVfufhpbw3ON1mNM_K2hFhJPbNCoPf6HywWHyMFquyOLvb2FzQ0V4fl_EwSLWUGmLJxSFgg-JKBpuB5A6jgNfL5gdx6jvplxhPYu1mL7kK4nWwyaneFWPTx0ngQ2BnnNN96f7BUbDQdGePU'
    },
    data: {
          query: `{
                    projects {
                      title
                      url
                      img {
                          url
                          }
                      about{
                       html
                      }

                      }
                  }`
            }
  };

  let projects = this.projects;

      axios(getprojects)
      .then(function (response) {
         //console.log(response);
            projects.push(response.data.data.projects)
            this.projects = projects[0];

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
    }

  },

};
</script>