<template>
  <b-container class="my-5">
    <b-row align-h="center">
      <b-col cols="12" md="6">
        <h1>Welcome to my Portfolio!</h1>
        <p class="lead">
          Hello! My name is Brandon!! I'm a seasoned Web Developer based in Reno, with over 14 years of professional experience crafting and deploying innovative web solutions. My journey in the tech industry has been marked by a comprehensive 6-year stint in the corporate world, where I honed my skills in web engineering, contributing significantly to various high-stakes projects.

My expertise lies in a balanced blend of front-end and back-end technologies. On the front-end side, I excel in creating intuitive and dynamic user experiences, leveraging CMS, CRM systems, and optimizing for SEO to ensure maximum visibility and user engagement. I'm well-versed in modern development approaches including the Jamstack architecture, and proficient in JavaScript and its associated technologies, ensuring seamless and responsive design.

In the realm of back-end development, I specialize in DevOps practices, streamlining the deployment pipeline and enhancing the scalability and security of web applications. My programming skills extend to PHP and Golang, allowing me to build robust server-side applications. Additionally, I have extensive experience integrating and customizing content management systems like WordPress, enhancing them to meet unique business needs, and have a solid understanding of marketing platforms such as HubSpot, enabling me to bridge the gap between development and marketing efforts effectively.

I am passionate about using my skills to develop web solutions that drive business growth and improve user experience. I'm always looking for new challenges and opportunities to leverage my diverse skill set in web development. Let's connect and explore how we can create impactful web solutions together.
        </p>
        <b-button variant="primary" @click="sendEmail">
    Email Me!!
  </b-button>

  <b-button class="ml-4"
    href="tel:+7752031010"
    variant="primary"
  >
    <b-icon-phone-fill></b-icon-phone-fill>
    Call Me!!
  </b-button>
      </b-col>
      <b-col cols="12" md="6" class="d-none d-md-block">
        <b-img class="firstpic m-4" src="../assets/webdeveloper.webp" alt="Web Deving in Reno Image" fluid />
        <b-img class="secondpic m-4" src="../assets/measwebdeveloper.webp" alt="Web Deving in Reno Image" fluid />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BButton, BIconPhoneFill } from 'bootstrap-vue';
export default {
  name: 'IntroductionSection',
  components: {
    BButton,
    BIconPhoneFill
  },
  methods: {
    sendEmail() {
      // Here you can define the logic to send an email
      // For example, you might open the user's default email program:
      window.location.href = "mailto:trujillobrandon2@gmail.com?Subject=LakeWebDesigns%20Email%20Inquiry%20Button";
    }
  }
  // Other component options
}
</script>

<style scoped>
/* Add custom styles here if needed */
.lead {
  font-weight:400;
  color:#00FFFF;
}
</style>